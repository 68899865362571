.rdrDefinedRangesWrapper {
  display: none;
  font-size: 12px;
  width: 226px;
  border: solid 1px #000;
  background: #fff;
}
.rdrCalendarWrapper {
  /* background: #000; */
  font-size: 15px;
  border: solid 1px #0a9959;
  border-radius: 5px;
}
